import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

import { isFuture, parse } from 'date-fns'
import { fromJS, List, Map } from 'immutable'
import { createSelector } from 'reselect'

import {
  CARE_ADVISOR_TRUNK_PHONE_NUMBER,
  Customer,
  CustomerCopy,
  CustomerName,
  CustomerPropertiesMap,
  DEFAULT_MICROSITE_CONFIG,
  DEFAULT_PASSWORD_POLICY,
  EAPAndHPIProgramSupport,
  EligibilityType,
  getCustomerConfiguration,
  getIsAnyCustomerProgramEAPCoaching,
  getIsAnyCustomerProgramEAPMeds,
  getIsAnyCustomerProgramHPIMeds,
  getIsAnyCustomerProgramHPITherapy,
  ImmutableTypedMap,
  PROGRAM_MEMBERSHIP_TYPES,
  ProgramCategory,
  ProgramCustomerPropertyId,
  PROGRAMS,
  SESSION_COUNT_STARTS_ON_FORMAT,
  SessionLimitByProgram,
  YearProgramCoverage,
} from '@lyrahealth-inc/shared-app-logic'
import { stringUtils } from '@lyrahealth-inc/ui-core'
import { getCustomerCopy as getCustomerCopyHelper } from '@lyrahealth-inc/ui-core-crossplatform/src/utils/customerCopy/getCustomerCopy'

import {
  areAllCustomerProgramsBHBExclusive,
  areAllCustomerProgramsEAPExclusive,
} from '../../common/constants/healthPlanConstants'
import { convertTriageTreatmentOptionToProgram } from '../../common/utils/onboardUtils'
import { getProgramConfigByProvider } from '../../common/utils/providerUtils'
import {
  getHealthPlanEligibilityType,
  getUsedSessionCount,
  getUsedSessionCountByProgram,
} from '../../features/healthPlan/data/healthPlanSelectors'
import {
  getOnboardProviderProfileData,
  getOnboardSelectedTreatmentOption,
} from '../../features/onboard/data/onboardSelectors'

export const getCustomer = (state: $TSFixMe) => state.get('customer', Map())
export const getCustomerPropertiesMap = (state: $TSFixMe) => state.get('customerProperties', Map())

export const getTreatmentRecommendation = (state: $TSFixMe) => getOnboardSelectedTreatmentOption(state)

// If you need to use the customer's name in text to be displayed, use
// getCustomerInfo below to get the customer's
// name with proper casing. getCustomerName returns a lowercased name.
export const getCustomerName = createSelector(
  getCustomer,
  (customer: ImmutableTypedMap<Customer>) => customer?.get('name', '') ?? '',
)

export const getHealthPlan = (state: $TSFixMe) => state.get('healthPlan', Map())

export const getHPAndInfractionCardReminderType = createSelector(getHealthPlan, (healthPlan: any) =>
  (healthPlan as $TSFixMe)?.get('HPAndInfractionCardReminderType'),
)

export const getReceiveHealthPlanPrompt = createSelector(getHealthPlan, (healthPlan: any) =>
  (healthPlan as $TSFixMe)?.get('receiveHealthPlanPrompt'),
)

export const getUnresolvedInfractions = createSelector(getHealthPlan, (healthPlan: any) =>
  (healthPlan as $TSFixMe)?.get('unresolvedInfractions'),
)

export const getCustomerId = createSelector(getCustomer, (customer: Customer) => (customer as $TSFixMe)?.get('id'))

export const getCustomerInfo = createSelector(getCustomer, (customer: Customer) => (customer as $TSFixMe)?.get('info'))

export const getCustomerHealthPlansList = createSelector(getCustomer, (customerSelector) => {
  return customerSelector.get('healthPlansList')
})

export const getCustomerNonIntegratedHealthPlansList = createSelector(getCustomer, (customerSelector) => {
  return customerSelector.get('nonIntegratedHealthPlansList')
})

export const getProgramCoverageBreakdown = createSelector(getCustomer, (customerSelector) => {
  const programCoverageBreakDown = customerSelector.getIn(['programCoverageBreakdown', `${new Date().getFullYear()}`])
  return programCoverageBreakDown ?? Map()
})

export const getIsCustomerHPIExclusive = createSelector(getProgramCoverageBreakdown, (customerPrograms: object) => {
  return areAllCustomerProgramsBHBExclusive(fromJS(customerPrograms))
})

export const getIsCustomerEAPExclusive = createSelector(getProgramCoverageBreakdown, (customerPrograms: object) => {
  return areAllCustomerProgramsEAPExclusive(fromJS(customerPrograms))
})

const convertMapToYearProgramCoverage = (customerPrograms: $TSFixMe): YearProgramCoverage => {
  const coverageObject = {}

  customerPrograms.forEach((value: any, key: ProgramCustomerPropertyId) => {
    coverageObject[key] = Object.fromEntries(value) as EAPAndHPIProgramSupport
  })

  return coverageObject
}

export const getIsCustomerHPIMeds = createSelector(getProgramCoverageBreakdown, (customerPrograms: object) => {
  const yearProgramCoverages = convertMapToYearProgramCoverage(fromJS(customerPrograms))
  return getIsAnyCustomerProgramHPIMeds(yearProgramCoverages)
})

export const getIsCustomerEAPMeds = createSelector(getProgramCoverageBreakdown, (customerPrograms: object) => {
  const yearProgramCoverages = convertMapToYearProgramCoverage(fromJS(customerPrograms))
  return getIsAnyCustomerProgramEAPMeds(yearProgramCoverages)
})

export const getIsCustomerProgramEAPCoaching = createSelector(
  getProgramCoverageBreakdown,
  (customerPrograms: object) => {
    const yearProgramCoverages = convertMapToYearProgramCoverage(fromJS(customerPrograms))
    return getIsAnyCustomerProgramEAPCoaching(yearProgramCoverages)
  },
)

export const getIsCustomerProgramHPITherapy = createSelector(
  getProgramCoverageBreakdown,
  (customerPrograms: object) => {
    const yearProgramCoverages = convertMapToYearProgramCoverage(fromJS(customerPrograms))
    return getIsAnyCustomerProgramHPITherapy(yearProgramCoverages)
  },
)

export const getIsCustomerEAPAndHPISupported = createSelector(
  getIsCustomerHPIExclusive,
  getIsCustomerEAPExclusive,
  (isCustomerHPIExclusive, isCustomerEAPExclusive) => {
    return !isCustomerHPIExclusive && !isCustomerEAPExclusive
  },
)

export const getCustomerSupportedPrograms = createSelector(getCustomer, (customerSelector) => {
  const supportedPrograms = customerSelector.get('supportedPrograms')
  if (!supportedPrograms) {
    return fromJS([])
  }
  return supportedPrograms
})

export const getIsAUDSupported = createSelector(getCustomerSupportedPrograms, (supportedPrograms) => {
  return supportedPrograms.includes(PROGRAMS.AlcoholUseDisorderTherapy.customerPropertyId)
})

export const getIsDirectAccessMedsSupported = createSelector(getCustomerSupportedPrograms, (supportedPrograms) => {
  return supportedPrograms.includes(PROGRAMS.DIRECT_ACCESS_MEDS.customerPropertyId)
})

export const getCustomerDependentTypes = createSelector(getCustomer, (customer) => customer?.get('dependentTypes'))

export const getCustomerCopy = createSelector(
  [
    getCustomerPropertiesMap,
    getCustomerName,
    (state: $TSFixMe) => getHealthPlanEligibilityType(state),
    getCustomerInfo,
  ],
  (
    customerPropertiesMap: ImmutableTypedMap<CustomerPropertiesMap>,
    customerName: string,
    eligibilityType: EligibilityType,
    customerDisplayName: string,
  ): CustomerCopy => {
    const customerCopy = getCustomerCopyHelper(
      customerName,
      customerPropertiesMap?.toJS(),
      eligibilityType,
      customerDisplayName,
    )
    return customerCopy
  },
)

export const getShouldDisplayCareTeamContactInfo = createSelector(
  getCustomerCopy,
  (copy) => copy?.shouldDisplayCareTeamContactInfo ?? true,
)

export const getLegalNotice = createSelector(getCustomerCopy, (copy) => copy?.legalNotice ?? '')

export const getGenerateEmployeeEligibilityErrorMessage = createSelector(
  [getCustomerCopy],
  (copy) => copy?.generateEmployeeEligibilityError,
)

export const getGenerateEligibilityQuestionTitle = createSelector(
  [getCustomerCopy],
  (copy) => copy?.generateEligibilityQuestionTitle,
)

export const getShowEligibilityQuestionForInternationalRegistration = createSelector(
  [getCustomerCopy],
  (copy) => copy?.showEligibilityQuestionForInternationalRegistration ?? false,
)

export const getGenerateEligibilityDependentTitle = createSelector(
  [getCustomerCopy],
  (copy) => copy?.generateEligibilityDependentTitle,
)

export const getGenerateConfirmEligibilityFormSubHeaderText = createSelector(
  [getCustomerCopy],
  (copy) => copy?.generateConfirmEligibilityFormSubHeaderText,
)

export const getGenerateConfirmEligibilityFormDefaultError = createSelector(
  [getCustomerCopy],
  (copy) => copy?.generateConfirmEligibilityFormDefaultError,
)

export const getEligibilityQuestionSubtitle = createSelector(
  [getCustomerCopy],
  (copy) => copy?.eligibilityQuestionSubtitle,
)

export const getDependentButtonText = createSelector(getCustomerCopy, (copy) => copy?.dependentButtonText)

export const getCustomEligibilityMetadata = createSelector(getCustomerCopy, (copy) => copy?.eligibilityMetadata)

export const getGenerateEligibilityCheckSubmissionErrors = createSelector(
  [getCustomerCopy],
  (copy) => copy?.generateEligibilityCheckSubmissionErrors,
)

export const getShouldDisplayBCTProviderBenefits = createSelector(
  getCustomerCopy,
  (copy) => copy?.shouldDisplayBCTProviderBenefits ?? false,
)

export const getSMSConsent = createSelector(getCustomerCopy, (copy) => copy?.smsConsent)

const getLocationFinder = createSelector(getCustomerCopy, (copy) => copy?.locationFinder)

export const getLocationOnsiteFormat = createSelector(getLocationFinder, (copy) => copy?.onsiteFormat)

export const getLocationOnsiteDescription = createSelector(getLocationFinder, (copy) => copy?.onsiteDescription)

const getModalityQuestionnaireText = createSelector(getCustomerCopy, (copy) => copy?.modalityQuestionnaireText)

export const getSubpopulations = createSelector(getCustomerCopy, (copy) => copy?.subpopulations)

export const getSubpopulations_lctTeens = createSelector(getCustomerCopy, (copy) => copy?.subpopulations_lctTeens)

export const getCustomerRedirection = createSelector(getCustomerCopy, (copy) => copy?.customerRedirection)

export const getServicesCovered = createSelector(getCustomerCopy, (copy) => copy?.servicesCovered)

export const getServicesNotCovered = createSelector(getCustomerCopy, (copy) => copy?.servicesNotCovered)

export const getShouldUseCustomChildCare = createSelector(getCustomerCopy, (copy) => copy?.shouldUseCustomChildCare)

export const getModalityLabelAddendums = createSelector(
  getModalityQuestionnaireText,
  (copy) => copy?.modalityLabelAddendums,
)

export const getCostMessagingModalText = createSelector(getCustomerCopy, (copy) => copy?.costMessagingModalText)

export const getHideChildSearchCTA = createSelector(
  getCustomerPropertiesMap,
  (customerPropertiesMap: ImmutableTypedMap<CustomerPropertiesMap>) =>
    // @ts-expect-error TS(2345): Argument of type 'boolean' is not assignable to pa... Remove this comment to see the full error message
    customerPropertiesMap?.get('shouldHideChildSearchCTA', false) === 'true',
)

export const getCustomerLaunchDate = createSelector(
  getCustomerPropertiesMap,
  (customerPropertiesMap: ImmutableTypedMap<CustomerPropertiesMap>) => customerPropertiesMap?.get('launchDate'),
)

export const getBeforeLaunch = createSelector(
  getCustomerPropertiesMap,
  (state: $TSFixMe) => state.getIn(['appGlobals', 'bypassComingSoonBeforeLaunch']),
  (customerPropertiesMap: ImmutableTypedMap<CustomerPropertiesMap>, bypassComingSoon: boolean) => {
    if (bypassComingSoon) {
      return false
    }
    const launchDate = customerPropertiesMap?.get('launchDate')
    return launchDate ? isFuture(new Date(launchDate)) : false
  },
)

export const getCustomerApproveWithNoEligibilityCheck = createSelector(
  getCustomerPropertiesMap,
  (customerPropertiesMap: ImmutableTypedMap<CustomerPropertiesMap>) =>
    customerPropertiesMap?.get('approveWithNoEligibilityCheck', 'false') === 'true',
)

export const getIsCustomerOptedOutOfEligibilityCheck = createSelector(
  getCustomerPropertiesMap,
  (customerPropertiesMap: ImmutableTypedMap<CustomerPropertiesMap>) =>
    customerPropertiesMap?.get('optOutOfEligibilityCheck', 'false') === 'true',
)

export const getInfractionHandlingEnabled = createSelector(
  getCustomerPropertiesMap,
  (customerPropertiesMap: ImmutableTypedMap<CustomerPropertiesMap>) =>
    customerPropertiesMap?.get('infractionHandlingEnabled', 'true') === 'true',
)

export const getCustomerWorkLifeBenefits = createSelector(
  getCustomerPropertiesMap,
  (customerPropertiesMap: ImmutableTypedMap<CustomerPropertiesMap>) => customerPropertiesMap?.get('worklife', ''),
)

export const getCustomerWorkLifePhone = createSelector(
  getCustomerPropertiesMap,
  (customerPropertiesMap: ImmutableTypedMap<CustomerPropertiesMap>) => customerPropertiesMap?.get('worklifephone'),
)

export const getIsAttestationEnabled = createSelector(
  getCustomerPropertiesMap,
  (customerPropertiesMap: ImmutableTypedMap<CustomerPropertiesMap>) =>
    customerPropertiesMap?.get('isAttestationEnabled', 'false') === 'true',
)

export const getIsCustomerDependentTypesEnabled = createSelector(
  getCustomerPropertiesMap,
  (customerPropertiesMap: ImmutableTypedMap<CustomerPropertiesMap>) =>
    customerPropertiesMap?.get('isDependentTypesEnabled', 'false') === 'true',
)

export const getShouldHideEthnicityField = createSelector(
  getCustomerPropertiesMap,
  (customerPropertiesMap: ImmutableTypedMap<CustomerPropertiesMap>) =>
    customerPropertiesMap?.get('hideEthnicityRegistrationQuestion', 'false') === 'true',
)

export const getShouldDisplayCareOptionsPanelWithWordBubbles = createSelector(
  getCustomerPropertiesMap,
  (customerPropertiesMap: ImmutableTypedMap<CustomerPropertiesMap>) =>
    // @ts-expect-error TS(2345): Argument of type '"shouldDisplayCareOptionsPanelWi... Remove this comment to see the full error message
    customerPropertiesMap?.get('shouldDisplayCareOptionsPanelWithWordBubbles', 'false') === 'true',
)

export const getShouldSeeCareAdvocateRequestForm = createSelector(
  getCustomerPropertiesMap,
  (customerPropertiesMap: ImmutableTypedMap<CustomerPropertiesMap>) =>
    customerPropertiesMap?.get('shouldSeeCareAdvocateRequestForm', 'false') === 'true',
)

export const getHideDependentField = createSelector(
  getCustomerPropertiesMap,
  (customerPropertiesMap: ImmutableTypedMap<CustomerPropertiesMap>) =>
    customerPropertiesMap?.get('hideDependentField', 'false') === 'true',
)

export const getIsMFARequiredForUsers = createSelector(
  getCustomerPropertiesMap,
  (customerPropertiesMap: ImmutableTypedMap<CustomerPropertiesMap>) =>
    customerPropertiesMap?.get('isMFARequiredForUsers', 'false') === 'true',
)

export const getIsLiveChatEnabled = createSelector(
  getCustomerPropertiesMap,
  (customerPropertiesMap: ImmutableTypedMap<CustomerPropertiesMap>): boolean =>
    customerPropertiesMap?.get('enabledChat', 'false') === 'true',
)

export const getLiveMessagingEnabled = createSelector(
  getCustomerPropertiesMap,
  (customerPropertiesMap: ImmutableTypedMap<CustomerPropertiesMap>) =>
    customerPropertiesMap?.get('liveMessagingEnabled', 'false') === 'true',
)

export const getGuidedSelfCareEnabled = createSelector(
  getCustomerPropertiesMap,
  (customerPropertiesMap: ImmutableTypedMap<CustomerPropertiesMap>) =>
    customerPropertiesMap?.get('guidedSelfCareEnabled', 'false') === 'true',
)

export const getCustomerPhone = createSelector(
  getCustomerPropertiesMap,
  (getCustomerPropertiesMap: ImmutableTypedMap<CustomerPropertiesMap>) => getCustomerPropertiesMap?.get('phone'),
)

export const getNumberOfSessions = createSelector(
  getCustomerPropertiesMap,
  (getCustomerPropertiesMap: ImmutableTypedMap<CustomerPropertiesMap>) =>
    getCustomerPropertiesMap?.get('numberOfSessions'),
)

export const getNumberOfSessionsByProgram = createSelector(
  getCustomerPropertiesMap,
  (getCustomerPropertiesMap: ImmutableTypedMap<CustomerPropertiesMap>) => {
    const jsonStr = getCustomerPropertiesMap?.get('numberOfSessionsByProgram')
    if (jsonStr) {
      return JSON.parse(jsonStr) as SessionLimitByProgram
    } else {
      return undefined
    }
  },
)

export const getDisplayVisitsPerIssuePerYear = createSelector(
  getCustomerPropertiesMap,
  (getCustomerPropertiesMap: ImmutableTypedMap<CustomerPropertiesMap>) =>
    getCustomerPropertiesMap?.get('displayVisitsPerIssuePerYear'),
)

export const getGraphicContentGroup = createSelector(
  getCustomerPropertiesMap,
  (getCustomerPropertiesMap: ImmutableTypedMap<CustomerPropertiesMap>) =>
    // @ts-expect-error TS(2345): Argument of type '"graphicContentGroup"' is not as... Remove this comment to see the full error message
    getCustomerPropertiesMap?.get('graphicContentGroup', ''),
)

export const getCancellationLimit = createSelector(
  getCustomerPropertiesMap,
  (getCustomerPropertiesMap: ImmutableTypedMap<CustomerPropertiesMap>) =>
    getCustomerPropertiesMap?.get('cancellationLimit'),
)

export const getCancellationsIncludedInSessionLimits = createSelector(
  getCustomerPropertiesMap,
  (getCustomerPropertiesMap: ImmutableTypedMap<CustomerPropertiesMap>) =>
    getCustomerPropertiesMap?.get('cancellationsIncludedInSessionLimits', 'false') === 'true',
)

export const getFAQText = createSelector(
  getCustomerPropertiesMap,
  (getCustomerPropertiesMap: ImmutableTypedMap<CustomerPropertiesMap>) => getCustomerPropertiesMap?.get('faqText'),
)

export const getBenefitsPortalLink = createSelector(
  getCustomerPropertiesMap,
  (getCustomerPropertiesMap: ImmutableTypedMap<CustomerPropertiesMap>) =>
    getCustomerPropertiesMap?.get('benefitsPortalLink'),
)

export const getLwMobileEnabled = createSelector(
  getCustomerPropertiesMap,
  (customerPropertiesMap: ImmutableTypedMap<CustomerPropertiesMap>) =>
    customerPropertiesMap?.get('lwMobileEnabled', 'false') === 'true',
)

export const getSessionCountStartsOn = createSelector(
  getCustomerPropertiesMap,
  (customerPropertiesMap: ImmutableTypedMap<CustomerPropertiesMap>) =>
    customerPropertiesMap?.get('sessionCountStartsOn'),
)

export const getSessionCountStartsOnDate = createSelector(getSessionCountStartsOn, (sessionCountStartsOn) =>
  parse(sessionCountStartsOn || 'Jan-01', SESSION_COUNT_STARTS_ON_FORMAT, new Date()),
)

export const getHealthPlanExtensionEnabled = createSelector(
  getCustomerPropertiesMap,
  (customerPropertiesMap: ImmutableTypedMap<CustomerPropertiesMap>) =>
    customerPropertiesMap?.get('healthPlanExtensionEnabled', 'false') === 'true',
)

export const getDepartingRedirectURL = createSelector(
  getCustomerPropertiesMap,
  (customerPropertiesMap: ImmutableTypedMap<CustomerPropertiesMap>) =>
    // @ts-expect-error TS(2345): Argument of type '"departingRedirectUrl"' is not a... Remove this comment to see the full error message
    customerPropertiesMap?.get('departingRedirectUrl'),
)

export const getIsCustomerCareTeam = createSelector(getCustomerName, (name) => name === 'care')

export const getIsCustomerLyra = createSelector(getCustomerName, (name) => ['lyrians', 'lyrians-2'].includes(name))

export const getIsCustomerLyraBootcamp = createSelector(getCustomerName, (name) => name === 'lyrabootcamp')

export const getIsCustomerApple = createSelector(getCustomerName, (name) => name === 'apple')

export const getSelectedProgram = createSelector(
  (state: $TSFixMe) => getOnboardProviderProfileData(state),
  getTreatmentRecommendation,
  (provider: $TSFixMe, selectedTreatmentOption: $TSFixMe) =>
    (
      convertTriageTreatmentOptionToProgram({ treatmentOptionName: selectedTreatmentOption }) ??
      getProgramConfigByProvider(provider.toJS())
    )?.customerPropertyId,
)

export const getProgramConfiguration = createSelector(
  getSelectedProgram,
  getProgramCoverageBreakdown,
  (selectedProgram, customerPrograms) => customerPrograms.get(selectedProgram),
)

export const getIsCustomerProgramHPIEligible = createSelector(
  getProgramConfiguration,
  (programConfiguration) =>
    programConfiguration?.get('bhbSupported') || programConfiguration?.get('specialtyBhbSupported'),
)

export const getIsCustomerProgramHPIExclusive = createSelector(
  getProgramConfiguration,
  getIsCustomerProgramHPIEligible,
  (programConfiguration, isCustomerProgramHPIEligible) =>
    !!(isCustomerProgramHPIEligible && !programConfiguration?.get('eapSupported')),
)

export const getCustomerConfigurationValue = createSelector(
  getProgramCoverageBreakdown,
  getHealthPlanExtensionEnabled,
  (customerPrograms, healthPlanExtensionEnabled) => {
    return getCustomerConfiguration(customerPrograms?.toJS(), healthPlanExtensionEnabled)
  },
)

export const getEssentialsDisabled = createSelector(
  getCustomerPropertiesMap,
  (customerPropertiesMap: ImmutableTypedMap<CustomerPropertiesMap>) =>
    customerPropertiesMap?.get('essentialsDisabled', 'false') === 'true',
)

export const getIsCustomerInternational = createSelector(
  getCustomer,
  (customer) => customer?.get('internationalSupport') ?? false,
)

export const getIsHealthPlanDirect = createSelector(
  getCustomerPropertiesMap,
  (customerPropertiesMap: ImmutableTypedMap<CustomerPropertiesMap>) =>
    customerPropertiesMap?.get('isHealthPlanDirect', 'false') === 'true',
)

export const getProgramMembershipType = createSelector(
  getCustomerPropertiesMap,
  (customerPropertiesMap: ImmutableTypedMap<CustomerPropertiesMap>) =>
    customerPropertiesMap?.get('programMembershipType') ?? PROGRAM_MEMBERSHIP_TYPES.EMPLOYEE,
)

export const getICASUsername = createSelector(
  getCustomerPropertiesMap,
  getCustomerName,
  (customerPropertiesMap: ImmutableTypedMap<CustomerPropertiesMap>, customerName) =>
    customerPropertiesMap?.get('icasUsername', customerName),
)

export const getICASPassword = createSelector(
  getCustomerPropertiesMap,
  getCustomerName,
  (customerPropertiesMap: ImmutableTypedMap<CustomerPropertiesMap>) =>
    customerPropertiesMap?.get('icasPassword', 'global'),
)

export const getICASCredentials = createSelector(getICASUsername, getICASPassword, (username, password) => {
  return { username: username, password: password }
})

export const getEmployeeRegistrationNomenclature = createSelector(
  getCustomerPropertiesMap,
  (customerPropertiesMap: ImmutableTypedMap<CustomerPropertiesMap>) =>
    customerPropertiesMap?.get('employeeRegistrationNomenclature'),
)

export const getDependentRegistrationNomenclature = createSelector(
  getCustomerPropertiesMap,
  (customerPropertiesMap: ImmutableTypedMap<CustomerPropertiesMap>) =>
    customerPropertiesMap?.get('dependentRegistrationNomenclature'),
)

export const getEligibilityTitleValues = createSelector(
  [getEmployeeRegistrationNomenclature, getCustomerInfo],
  (nomenclature, customerInfo) => {
    return {
      employeeType: nomenclature || 'other',
      hasCustomerName: customerInfo ? 'yes' : 'other',
      customerName: customerInfo,
    }
  },
)

export const getCustomerPhoneCTARegisterVerifyEmail = createSelector(
  [getIsCustomerInternational, getCustomerPhone, (state, email) => email],
  (isCustomerInternational, customerPhone = CARE_ADVISOR_TRUNK_PHONE_NUMBER, email) =>
    isCustomerInternational ? (
      <FormattedMessage
        defaultMessage='We sent a new link to {email}. Please click the link in the message to confirm your email address. If you don’t receive an email, check your spam folder or <link>call us</link>.'
        description='Text informing the user to verify their email address by clicking the link in the email sent and to contact Lyra Health directly if they do not receive an email'
        values={{
          link: (text: string) => <Link to='/care-team-phone-numbers'>{text}</Link>,
          email,
        }}
      />
    ) : (
      <FormattedMessage
        defaultMessage='We sent a new link to {email}. Please click the link in the message to confirm your email address. If you don’t receive an email, check your spam folder or call us at {phone}.'
        description='Text informing the user to verify their email address by clicking the link in the email sent and to contact Lyra Health directly if they do not receive an email'
        values={{
          email,
          phone: stringUtils.formatPhoneNumber(customerPhone),
        }}
      />
    ),
)

export const getCustomerPhoneCTAEmailConfirmation = createSelector(
  getIsCustomerInternational,
  getCustomerPhone,
  (isCustomerInternational, customerPhone = CARE_ADVISOR_TRUNK_PHONE_NUMBER) =>
    isCustomerInternational ? (
      <FormattedMessage
        defaultMessage='If you don’t receive an email, check your spam folder or <link>call us</link>.'
        description='Link shown to users to notify Lyra Health directly if they do not receive an email'
        values={{
          link: (text: string) => <Link to='/care-team-phone-numbers'>{text}</Link>,
        }}
      />
    ) : (
      <FormattedMessage
        defaultMessage='If you don’t receive an email, check your spam folder or call us at {phone}.'
        description='Text shown to users to call the specified phone number if they do not receive an email'
        values={{
          phone: stringUtils.formatPhoneNumber(customerPhone),
        }}
      />
    ),
)

export const getCustomerPhoneCTAHealthPlanInformation = createSelector(
  getIsCustomerInternational,
  getCustomerPhone,
  (isCustomerInternational, customerPhone = CARE_ADVISOR_TRUNK_PHONE_NUMBER) =>
    isCustomerInternational ? (
      <FormattedMessage
        defaultMessage='Need to speak with someone now? <link>Call us</link>. We’re here 24/7.'
        description='Text description shown to user to contact Lyra Health directly by clicking the link provided if they need to speak with a representative'
        values={{
          link: (text: string) => <Link to='/care-team-phone-numbers'>{text}</Link>,
        }}
      />
    ) : (
      <FormattedMessage
        defaultMessage='Need to speak with someone now? Call us at {phone}. We’re here 24/7.'
        description='Text description shown to user to call the phone number provided when they need to contact Lyra Health'
        values={{
          phone: stringUtils.formatPhoneNumber(customerPhone),
        }}
      />
    ),
)

export const getOktaPasswordPolicy = createSelector(
  getCustomerPropertiesMap,
  (customerPropertiesMap: ImmutableTypedMap<CustomerPropertiesMap>) => {
    const oktaPasswordPolicy = customerPropertiesMap?.get('oktaPasswordPolicy')
    return oktaPasswordPolicy ? JSON.parse(oktaPasswordPolicy) : DEFAULT_PASSWORD_POLICY
  },
)

export const getIsAppleSSODisabled = createSelector(
  getCustomerPropertiesMap,
  (customerPropertiesMap: ImmutableTypedMap<CustomerPropertiesMap>) =>
    customerPropertiesMap?.get('isAppleSSODisabled', 'false') === 'true',
)

export const getIsGoogleSSODisabled = createSelector(
  getCustomerPropertiesMap,
  (customerPropertiesMap: ImmutableTypedMap<CustomerPropertiesMap>) =>
    customerPropertiesMap?.get('isGoogleSSODisabled', 'false') === 'true',
)

export const getAccountLockedText = createSelector(getCustomerCopy, (copy) => copy?.accountLockedText)
export const getPasswordResetFailedText = createSelector(getCustomerCopy, (copy) => copy?.passwordResetFailedText)

export const getHideWorkLifeServicesForInternationalUser = createSelector(
  getCustomerCopy,
  (copy) => copy?.hideWorkLifeServicesForInternationalUser,
)

export const getHasBlockingEligibilityCheck = createSelector(
  getCustomerPropertiesMap,
  (customerPropertiesMap: ImmutableTypedMap<CustomerPropertiesMap>) =>
    customerPropertiesMap?.get('hasBlockingEligibilityCheck', 'false') === 'true',
)

export const getCheckAdultDependentEligibilityUsingDependentInfo = createSelector(
  getCustomerPropertiesMap,
  (customerPropertiesMap: ImmutableTypedMap<CustomerPropertiesMap>) =>
    customerPropertiesMap?.get('checkAdultDependentEligibilityUsingDependentInfo', 'false') === 'true',
)

export const getCheckChildDependentEligibilityUsingDependentInfo = createSelector(
  getCustomerPropertiesMap,
  (customerPropertiesMap: ImmutableTypedMap<CustomerPropertiesMap>) =>
    customerPropertiesMap?.get('checkChildDependentEligibilityUsingDependentInfo', 'false') === 'true',
)

export const getInfractionMessagingDisabled = createSelector(
  getCustomerPropertiesMap,
  (customerPropertiesMap: ImmutableTypedMap<CustomerPropertiesMap>) =>
    customerPropertiesMap?.get('infractionMessagingDisabled', 'false') === 'true',
)

export const getDefaultDirectCareNavigatorBookingLink = createSelector(
  getCustomerPropertiesMap,
  (getCustomerPropertiesMap: ImmutableTypedMap<CustomerPropertiesMap>) =>
    getCustomerPropertiesMap?.get('defaultDirectCareNavigatorBookingLink'),
)

export const getWorkLifeServiceDependentCareCode = createSelector(
  getCustomerPropertiesMap,
  (getCustomerPropertiesMap: ImmutableTypedMap<CustomerPropertiesMap>) =>
    getCustomerPropertiesMap?.get('workLifeServiceDependentCareCode'),
)

export const getIsWorkhubDisabled = createSelector(
  getCustomerPropertiesMap,
  (customerPropertiesMap: ImmutableTypedMap<CustomerPropertiesMap>) =>
    !customerPropertiesMap?.has('isWorkforceHubDisabled')
      ? false // if isWorkforceHubDisabled value does not exist as a customer property, assume Work Hub is enabled
      : customerPropertiesMap?.get('isWorkforceHubDisabled', 'false') === 'true',
)

export const getIsWorkhubEventsDisabled = createSelector(
  getCustomerPropertiesMap,
  (customerPropertiesMap: ImmutableTypedMap<CustomerPropertiesMap>) =>
    !customerPropertiesMap?.has('isWorkforceHubEventsDisabled')
      ? false
      : customerPropertiesMap?.get('isWorkforceHubEventsDisabled', 'false') === 'true',
)

export const getIsICASHubLinkDisabled = createSelector(
  getCustomerPropertiesMap,
  (customerPropertiesMap: ImmutableTypedMap<CustomerPropertiesMap>) =>
    customerPropertiesMap?.get('isICASHubLinkDisabled', 'false') === 'true',
)

export const getMicrositeConfig = createSelector(
  getCustomerPropertiesMap,
  (customerPropertiesMap: ImmutableTypedMap<CustomerPropertiesMap>) => {
    const micrositeConfig = customerPropertiesMap?.get('micrositeConfig')
    return micrositeConfig ? { ...DEFAULT_MICROSITE_CONFIG, ...JSON.parse(micrositeConfig) } : DEFAULT_MICROSITE_CONFIG
  },
)

export const isWalmartCustomer = (state: $TSFixMe) => getCustomerName(state) === CustomerName.WALMART

export const getIsEAPSessionCountExhausted = createSelector(
  [(state) => getUsedSessionCount(state), getNumberOfSessions],
  (usedSessionCount, numberOfSessions) =>
    numberOfSessions ? parseInt(numberOfSessions, 10) - (usedSessionCount ?? -1) <= 0 : false,
)

export const getIsEAPSessionCountExhaustedByProgramGetter = createSelector(
  [(state) => getUsedSessionCountByProgram(state), getNumberOfSessionsByProgram, getIsEAPSessionCountExhausted],
  (usedSessionCountByProgram, numberOfSessionsByProgram, isEAPSessionCountExhausted) =>
    (programCategory: ProgramCategory | undefined) => {
      if (!numberOfSessionsByProgram || !usedSessionCountByProgram || !programCategory) {
        return isEAPSessionCountExhausted
      }
      const programSessions = numberOfSessionsByProgram[programCategory]
      const usedSessions = usedSessionCountByProgram[programCategory] ?? 0
      return programSessions ? programSessions - usedSessions <= 0 : false
    },
)

export const getIsCoachingEnabled = createSelector(
  getCustomerPropertiesMap,
  (customerPropertiesMap: ImmutableTypedMap<CustomerPropertiesMap>) =>
    customerPropertiesMap?.get('stressManagement') === 'true',
)

export const getIsBlendedCareMedsAvailable = createSelector(
  getCustomerPropertiesMap,
  (customerPropertiesMap: ImmutableTypedMap<CustomerPropertiesMap>) =>
    customerPropertiesMap?.get('blendedCareMeds') === 'true',
)

export const getIsComplexCareEnabled = createSelector(
  getCustomerPropertiesMap,
  (customerPropertiesMap: ImmutableTypedMap<CustomerPropertiesMap>) =>
    customerPropertiesMap?.get('isComplexCareEnabled') === 'true',
)

export const getIsHPIRequiredForAppointmentBookingGetter = createSelector(
  [getIsCustomerProgramHPIEligible, getIsCustomerProgramHPIExclusive, getIsCustomerHPIExclusive],
  (isCustomerProgramHPIEligible, isCustomerProgramHPIExclusive, isCustomerHPIExclusive) =>
    (isEAPSessionCountExhausted: boolean) =>
      (isCustomerProgramHPIEligible && isEAPSessionCountExhausted) ||
      isCustomerProgramHPIExclusive ||
      isCustomerHPIExclusive,
)

/**
 * @deprecated. use `getCustomerCopy` instead
 */
export const getCustomerCopyMap = createSelector(
  getCustomer,
  (customer: Customer) => (customer as $TSFixMe)?.get('copies') as Map<string, unknown>,
)

// ===== START ==== below are selectors selecting for old customer copies which are used in web only

export const getFAQQuestions = createSelector(getCustomerCopyMap, (copy) => copy?.get('faqQuestions', List()))

export const getHideExtraHeaderOptions = createSelector(getCustomerCopyMap, (copy) =>
  copy?.get('hideExtraHeaderOptions', false),
)

const getMicrositeAndElsewhereTopBannerCopy = createSelector(getCustomerCopyMap, (copy) =>
  copy?.get('micrositeAndElsewhereTopBanner', Map()),
)

export const getMicrositeAndElsewhereTopBanners = createSelector(getMicrositeAndElsewhereTopBannerCopy, (copy) =>
  (copy as $TSFixMe)?.get('banners', List()),
)

export const getWorkLifeServicesCopy = createSelector(getCustomerCopyMap, (copy) => copy?.get('workLifeServices', []))

export const getGenerateEmployeeEligibilityError = createSelector(
  getCustomerCopy,
  (copy) => copy?.generateEmployeeEligibilityError,
)

export const getGenerateRegistrationSubHeaderText = createSelector(
  [getCustomerCopy],
  (copy) => copy?.generateRegistrationFormSubHeaderText,
)

// ==== END ==== above are selectors selecting for old customer copies which are used in web only

export const getIsSecondaryHealthPlanCollectionEnabled = createSelector(
  getCustomerPropertiesMap,
  (getCustomerPropertiesMap: ImmutableTypedMap<CustomerPropertiesMap>) =>
    getCustomerPropertiesMap?.get('isSecondaryHealthPlanCollectionEnabled', 'false') === 'true',
)

export const getIsLyraSocialCareEnabled = createSelector(
  getCustomerPropertiesMap,
  (getCustomerPropertiesMap: ImmutableTypedMap<CustomerPropertiesMap>) =>
    getCustomerPropertiesMap?.get('lyraSocialCareEnabled', 'false') === 'true',
)
